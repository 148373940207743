<template>
  <div class="form-element">
    <InputField
      :field-title="fieldTitle ? fieldTitle : $t('DIAS.VIDEO_URL')"
      :field-name="'video'"
      :rules="'required'"
      :type="'text'"
      :on-update="(value) => updateLink(value)"
      :placeholder="$t('DIAS.VIDEO_RESULT_PLACEHOLDER')"
      :cy-selector="'name-input'"
      :value="value"
      class="form-element"
    />
  </div>

  <div v-if="link"
       class="video-preview">
    <h3>
      {{ $t('DIAS.RESULT_VIDEO') }}
    </h3>
    <VimeoPlayer
      :video-url="link"
      :player-height="200"
      :player-width="400"
      @error="invalidLink"
    />
  </div>
</template>

<script>
import InputField from '../../yo-form/InputField.vue'
import VimeoPlayer from '../video/VimeoPlayer.vue'
export default {
  name: 'UploadVideo',
  components: {
    InputField,
    VimeoPlayer
  },
  props: {
    fieldTitle: {
      type: String,
      required: false,
      default: null
    },
    value: {
      type: String,
      required: false,
      default: ''
    },
    errorText: {
      type: String,
      required: false,
      default: ''
    }
  },
  emits: ['update-link', 'isValid'],
  data() {
    return {
      link: this.value
    }
  },
  methods: {
    updateLink(link) {
      this.link = link
      this.$emit('update-link', this.link)
      this.$emit('isValid', true)
    },
    invalidLink(value) {
      console.log('invalid link', value)
      this.$emit('isValid', false)
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

</style>
